.pane-bundles-with-product-panel-pane-1 {
  border-top: 1px solid $menu-secondary;
  margin-top: 16px;

  h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .view-content {
    display: flex;
    flex-direction: column;

    .bundle-row {
      display: flex;
      flex-wrap: wrap;

      // Center container
      .views-field-title {
        flex-grow: 2;
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
      }

      // Price / cart container
      div:nth-child(3) {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        height: 155px;

        .price-container {
          margin-bottom: 8px;

          .price {
            text-align: right;
          }
        }
      }
    }

  }
}
