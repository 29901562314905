.page-checkout {
  h1 {
    display: none;
  }

  .footer-links {
    display: none;
  }

  &.logged-in {
    .commerce-checkout-progress {
      li.account {
        display: none;
      }
    }
  }

  .commerce-checkout-progress {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;

    li {
      color: lighten($dark-grey, 10%);
      display: inline-block;
      font-size: 16px;
      margin: 0;
      padding-left: 50px;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      &::after {
        background: url('/profiles/academicbooks/themes/academic/images/icons/pil.svg') 100% 2px no-repeat;
        background-size: 16px 16px;
        content: ' ';
        height: 26px;
        position: absolute;
        width: 36px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        &::after {
          background: none;
          content: '';
        }
      }
    }
  }

  #commerce-checkout-coupon-ajax-wrapper {
    display: inline-block;
    margin-top: 18px;
    width: 50%;

    label {
      display: block;
    }

    .form-text {
      float: left;
      font-size: 16px;
      height: 30px;
      margin-right: 1em;
      width: 30%;
    }

    .form-submit {
      float: left;
      margin-top: -9px;
      padding: 4px 14px;
    }

    .description {
      display: none;
    }
  }

  .checkout-buttons {
    margin-top: 70px;

    .button-operator {
      display: none;
    }

    .checkout-continue {
      float: right;
    }

    .checkout-back {
      background-color: $main-blue;
      border: 0;
      color: $white;
      padding: 8px 25px;
    }

    .checkout-cancel {
      background-color: $main-blue;
      border: 0;
      border-radius: 3px;
      color: $white;
      float: left;
      font-size: 1.1em;
      padding: 8px 25px;
      text-transform: uppercase;
    }
  }
}

// REVIEW
.checkout_review {
  tbody {
    border: 0;
  }
}

.page-checkout-review {
  .pay-by-invoice-note {
    width: 450px;
    padding-left: 2em;
    color: #999;
  }
  input[type=radio],
  input[type=checkbox] {
    margin-top: -3px;
  }
}

.page-checkout-review,
.page-checkout {
  .checkout-review {
    tr {
      border: 0;
    }
  }

  .commerce_payment,
  .terms_conditions {
    margin-top: 20px;

    .form-type-checkbox {
      margin-bottom: 0;
    }

    label {
      padding-left: 4px;
    }
    legend {
      border: 0;
      margin-bottom: 6px;
    }
  }

  .terms_conditions {
    .form-type-checkbox {
      margin-top: 6px;
    }
  }

  .form-item-digital-terms {
    margin-top: 12px;

    label {
      padding-left: 4px;
    }

    .description {
      width: 450px;
      margin: .5em 0 0 1.8em;
    }
  }

  .line-item-summary {
    tr {
      border: 0;
    }
  }

  tr {
    background-color: transparent;
  }

  .views-table {
    width: 100%;
  }

  .checkout-help,
  .pane-title {
    display: none;
  }

  .field-cvr-number,
  .field-ean,
  .field-phone {
    label {
      font-weight: 400;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .delivery-information {
    background-color: $light-grey;
    margin-top: 30px;
    padding: 20px 30px;
    width: 45%;

    div.addressfield-container-inline:after {
      content: none;
    }
    .form-type-textfield {
      margin: 0;

      .form-text {
        background-color: $light-grey;
        border: none;
        color: $color-menu-mobile;
        padding: 0;
      }

      .description {
        display: none;
      }
    }

    .pane {
      border-bottom: 1px solid lighten($dark-grey, 20%);
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
// SHIPPING
.page-checkout-shipping {
  fieldset {
    margin-bottom: 25px;
    width: 65%;
  }

  .customer_profile_billing {
    &.form-wrapper {
      .form-submit {
        margin-top: 16px;
      }
    }
  }

  .form-type-select {
    label {
      display: block;
    }

    select {
      background: none;
      border: 1px solid lighten($dark-grey, 20%);
      height: 34px;
      min-width: 300px;
    }
  }

  legend {
    border: 0;
    color: $dark-grey;
    font-size: 18px;
    margin-bottom: 1px;

    &::after {
      content: ':';
    }
  }

  .form-checkbox,
  .form-radio {
    float: left;
    margin-right: 10px;
    vertical-align: top;
  }

  .field-widget-text-textfield {
    margin-bottom: 20px;
  }

  .shipping-notification {
    margin-top: 65px;
  }

  .custom-zipcode-wrapper {
    width: 100%;

    .ajax-progress.ajax-progress-throbber {
      clear: both;
      width: 100%;
    }

    .fieldset-wrapper {
      display: flex;
      flex-wrap: wrap;

      .form-item-commerce-shipping-service-details-custom-custom-zip {
        flex: 1;
        margin-bottom: 0;

        input {
          width: 6em;
        }
      }

      .form-submit {
        flex: 1;
        height: 2.2em;
        margin-top: 10px;
        padding: 0;
      }

      .form-type-item {
        color: $color-fifth;
        flex: 1 1 100%;
        margin: 0;
      }
    }
  }

  .form-item-commerce-shipping-shipping-service {
    background-color: $light-grey;
    border: 1px solid lighten($dark-grey, 10%);
    border-radius: 6px;
    padding: 8px 10px;
    width: 100%;

    label {
      font-weight: 600;
    }
  }

  .checkout-buttons {
    .checkout-back {
      background-color: $main-blue;
      border: 0;
      color: $white;
      padding: 8px 25px;
    }
  }

  .pickup-points-wrapper {
    h3 {
      background-color: $main-blue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: $white;
      font-size: 16px;
      margin-bottom: 0;
      padding: 7px 15px;
      width: 100%;
    }

    .form-type-item {
      margin-bottom: 0;
    }

    .pickup-points-content {
      border: 1px solid $dark-grey;
      border-top: 0;
      height: 400px;

      .pickup-points {
        float: left;
        height: 400px;
        overflow-y: auto;
        padding: 10px 30px;
        width: 50%;
      }

      .form-item {
        clear: both;
      }

      .map {
        float: left;
        height: 100%;
        width: 50%;
      }

      .form-radios {
        height: 380px;
      }

      .store-note {
        color: $red;
        font-size: .8em;
        font-style: italic;
      }

      .form-radio {
        cursor: pointer;
      }

      .form-type-radio {
        div {
          font-weight: 400;
          margin-bottom: 20px;
        }

        label {
          background-color: transparent;
          border-radius: 0;
          color: $black;
          cursor: pointer;
          float: left;
          font-size: 16px;
          font-weight: 600;
          padding: 0;
          width: 85%;
        }
      }
    }
  }

  .form-item {
    margin: 10px 0;
    width: 100%;

    .premise {
      margin-top: 10px;
    }

    &.form-type-checkbox {
      margin-bottom: 0;
    }
  }

  .form-type-textfield {
    &.form-item-customer-profile-shipping-commerce-customer-address-und-0-select-country,
    &.form-item-customer-profile-billing-commerce-customer-address-und-0-select-country {
      .form-text {
        width: 30%;
      }
      .description {
        color: lighten($dark-grey, 5%);
        padding: 3px 0 0 9px;
      }
    }
  }

  .form-text {
    border: 1px solid $light-grey;
    border-radius: 3px;
    color: $black;
    font-size: 14px;
    height: 34px;
    padding: 2px 10px;
    width: 100%;

    &:read-only {
      cursor: not-allowed;
    }

    &:read-only,
    &::placeholder {
      color: lighten($dark-grey, 5%);
    }
  }

  .form-item-customer-profile-shipping-commerce-customer-address-und-0-postal-code,
  .form-item-customer-profile-billing-commerce-customer-address-und-0-postal-code {
    margin-right: 2% !important;
    width: 30%;
  }

  .form-item-customer-profile-shipping-commerce-customer-address-und-0-locality,
  .form-item-customer-profile-billing-commerce-customer-address-und-0-locality {
    margin-right: 0 !important;
    width: 68%;
  }
}

// COMPLETE
.page-checkout-complete {
  h1 {
    color: $main-blue;
    font-size: 24px;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
  }

  .messages {
    display: none;
  }

  .main-content {
    color: $dark-grey;
    font-size: 16px;
    padding-top: 150px;
    width: 100%;

    .region-content {
      margin: 0 auto;
      padding-top: 22px;
      width: 60%;
    }
  }
}

.page-checkout-account {
  #edit-account-form-select > .form-wrapper {
    flex: none;
  }

  .or-field {
    display: flex;
    height: 500px;

    div {
      color: lighten($dark-grey, 10%);
      font-size: 1.1em;
      margin: auto 0;
      text-transform: uppercase;
    }
  }

  .create-account-link {
    background-color: $light-blue;
    border: 0;
    border-radius: 3px;
    color: $white;
    display: block;
    font-size: 1.1em;
    padding: 8px 25px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  .account_form {
    fieldset {
      border: 1px solid $light-grey;
      float: left;
      height: 500px;
      padding: 40px 60px;
      position: relative;
      width: 40%;

      &.without-login-block {
        padding-left: 65px;
        padding-top: 186px;

        .form-submit {
          margin-top: 16px;
          width: 100%;
        }

        .form-type-item {
          margin-top: 10px;
        }
      }

      legend {
        border: 0;
        position: absolute;
        text-transform: uppercase;
      }

      legend, label {
        color: $dark-grey;
        font-weight: 600;
      }

      .fieldset-wrapper {
        margin-top: 35px;
      }
    }

    #edit-account-form-select-login-request-password {
      clear: both;
      padding-top: 6px;
      text-align: right;
    }

    .form-item {
      label {
        display: block;
      }
    }

    .form-submit {
      float: right;
    }

    .form-text {
      width: 100%;
    }
  }
}
