blockquote {
  border: 2px solid lighten($black, 30%);
  border-radius: 8px;
  margin-bottom: 50px;
  padding: 20px 30px;
  position: relative;
  top: 33px;

  &::after {
    border-color: $white transparent;
    border-style: solid;
    border-width: 0 29px 29px;
    bottom: auto;
    content: '';
    display: block;
    left: 38px;
    position: absolute;
    right: auto;
    top: -26px;
    width: 0;
  }

  &::before {
    border-color: lighten($black, 30%) transparent;
    border-style: solid;
    border-width: 0 27px 27px;
    bottom: auto;
    content: '';
    display: block;
    left: 40px;
    position: absolute;
    right: auto;
    top: -29px;
    width: 0;
  }
}

h2 {
  font-weight: 600;
}

.not-front main {
  margin-top: 50px;
}

.show-print {
  display: none;
}

input,
select {
  &.error {
    border: 1px solid $red !important;
  }
}

.form-submit {
  background-color: $main-blue;
  border: 0;
  border-radius: 3px;
  color: $white;
  font-size: 1.1em;
  padding: 8px 25px;
  text-transform: uppercase;
}

.icon {
  &.icon-present {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/present.svg');
      background-size: 26px 26px;
      content: ' ';
      display: block;
      height: 26px;
      opacity: .8;
      position: absolute;
      width: 26px;
    }
  }

  &.icon-price-tag {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/price-tag.svg');
      background-size: 26px 26px;
      content: ' ';
      display: block;
      height: 26px;
      opacity: .8;
      position: absolute;
      width: 26px;
    }
  }

  &.icon-pointer-up {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/pointer-up.svg');
      background-size: 26px 26px;
      content: ' ';
      display: block;
      height: 26px;
      opacity: .8;
      position: absolute;
      width: 26px;
    }
  }

  &.icon-nonprofit {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/nonprofit.svg');
      background-size: 26px 26px;
      content: ' ';
      display: block;
      height: 26px;
      opacity: .8;
      position: absolute;
      width: 26px;
    }
  }

  &.icon-purse {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/purse.svg');
      background-size: 58px 58px;
      content: ' ';
      display: block;
      height: 58px;
      opacity: 1;
      width: 58px;
    }
  }

  &.icon-questionmark {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/questionmark.svg');
      background-size: 58px 58px;
      content: ' ';
      display: block;
      height: 58px;
      opacity: 1;
      width: 58px;
    }
  }

  &.icon-speechbubble {
    &::before {
      background: url('/profiles/academicbooks/themes/academic/images/icons/speechbubble.svg');
      background-size: 58px 58px;
      content: ' ';
      display: block;
      height: 58px;
      opacity: 1;
      width: 58px;
    }
  }
}
