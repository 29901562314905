@media (max-width: 600px) {
  section {
    .messages {
      margin-top: 140px;
    }
  }
  .pane-syllabus {
    .syllabus-list {
      .group-left {
        flex: 0 0 110px;
      }

      .group-right {
        flex: 0 0 190px;
      }
    }
  }

  .page-checkout-review {
    .view-footer {
      .line-item-summary {
        float: inherit;
      }
    }
  }
  .page-with-custom-menu {
    .node-product-list {
      .field-product {
        display: flex;
        float: none;
        width: 100%;
        height: 155px;
      }
    }
  }

  .product-full {
    .wrapper {
      width: 100%;
    }

    .wrapper-inner {
      .left-col {
        flex: 1;
        padding-right: 10px;
        width: 125px;
        min-width: 125px;

        img {
          height: auto;
          width: 100%;
        }
      }

      .middle-col {
        flex: 2;
        .pane-node-product-field-rating {
          display: none;
        }

        .pane-node-product-field-body {
          margin-top: 14px;
        }

        .product-references {
          li {
            width: 100%;
          }
          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 6px;
            margin: 10px;
          }
          .title {
            order: 2; flex: 1;
          }
          .price {
            order: 3; flex: 2; text-align: right;
          }
          .icon {
            order: 1;
            display: block;
            height: auto;
            margin: 0 6px 0 0;
            font-size: 18px;

            .icon-academicbook {
              height: 19px;
              width: 16px;
              margin-top: 0;
              position: absolute;
              top: 2px;
            }
          }
          .spacer {
            display: none;
          }
        }
      }
    }

    .wrapper-sub {
      display: none;
    }
  }

  .node-type-webform {
    main {
      width: 100%;
    }

    .pane-node-field-body {
      img {
        width: 100%;
        height: auto;
      }
    }

    .center-wrapper {
      width: 100%;

      select {
        width: 20%;
      }

      input[type=text],
      input[type=email],
      input[type=submit],
      .panel-panel {
        width: 100%;
      }

      .field-how-to {
        margin-left: 0;
        margin-right: .5em;
      }
    }
  }

  .pane-search-api-sorts-search-sorts {
    display: none;
  }

  #sliding-popup {
    background-color: darken($white, 3%);
    border: 0;
    border-radius: 0;
    border-top: 3px solid $main-blue;
    position: fixed;
    right: 0;
    width: 100%;

    h2 {
      display: none;
    }

    .find-more-button {
      clear: both;
      float: right;
      padding-right: 0 !important;
      padding-top: 16px !important;
    }
  }

  .frontpage-block {
    .field-productdisplay-reference {
      flex-basis: 100%;

      .group-left {
        flex-basis: 100px;
        flex-shrink: 0;
        width: 20%;
      }

      .group-right {
        width: 80%;
      }
    }
  }

  .page-checkout-shipping {
    .map {
      display: none;
    }

    .pickup-points-wrapper {
      .pickup-points-content {
        .pickup-points {
          width: 100%;
        }
      }
    }
  }

  footer {
    position: inherit;

    .first {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .page-user {
    .main-content {
      .tabs.primary {
        width: 100%;
      }
    }
  }

  .frontpage-carousel {
    display: none;
  }

  .page-with-custom-menu {
    .custom-menu-panel {
      position: relative;
    }

    .panel-col-first {
      width: 100%;

      .pane-content {
        display: none;
        position: absolute;
        width: 100%;
        z-index: 100;

        ul li ul {
          display: block;
        }
      }
    }

    .panel-col-last {
      padding-left: 0;
    }

    &.node-type-staff-list,
    &.node-type-staff-list.staff-list-1 {
      .field-staff {
        .group-left {
          width: 40% !important;
        }

        .group-right {
          width: 60% !important;
        }
      }

      .center-wrapper {
        .panel-col-last {
          width: 100%;
        }
      }

      .field-staff-block {
        .field-staff {
          width: 100% !important;
        }
      }
    }
  }

  .page-user {
    main {
      width: 100%;
    }

    .form-text,
    .form-select,
    .form-item {
      width: 100%;
      padding-top: 0;
    }

    .form-select {
      background: none;
      border: 1px solid lighten($dark-grey, 30%);
      height: 30px;
    }

    .main-content {
      .region-content {
        width: 100%;
      }
    }
  }

  .page-checkout {
    .block-commerce-checkout-progress {
      display: none;
    }

    .checkout-buttons {
      margin-top: 35px;
    }
  }

  .page-search {
    .grid--container {
      align-items: flex-start;

      .wrapper {
        align-items: stretch;
        flex-direction: column;
      }

      .right-col {
        flex: auto;

        div {
          text-align: left;
        }
      }

      div {
        text-align: left;
      }

      .price-container {
        float: left;
        margin-top: 16px;
      }

      .field-title {
        a {
          color: $black;
          font-size: 13px;
        }
      }

      .field-schilling-stock,
      .field-edition-year,
      .field-vocab-format,
      .field-label,
      .field-isbn {
        display: none !important;
      }

      .field-commerce-price {
        float: left;
        margin-top: 15px;
      }

      .field-product-reference {
        clear: both;
        float: left;
      }
    }

    .pager {
      li {
        display: none;
      }

      .pager-previous,
      .pager-next {
        display: block;

        a {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .page-with-custom-menu {
    .node-product-list {
      .field-product {
        display: flex;
        float: none;
        width: 100%;
        height: 155px;
      }
    }
  }

  .main-content {
    margin-top: 0;
    width: 95%;
  }

  .node-type-page {
    .content .panel-col-last .inside {
      margin: 0 .5em 0 0;
    }
    .content .panel-col-last,
    &.no-left-menu .pane-content,
    .pane-content {
      width: 100% !important;
    }
    .media-element-container .file-image img,
    .field-image img {
      width: 100%;
      height: auto;
    }
  }

  .node-type-bookshop-list {
    .group-left {
      display: none;
    }

    .group-middle {
      flex-basis: 100%;
    }

    .group-right {
      flex-basis: 100%;
      margin-top: 20px;
    }
  }

  .page-user-register {
    .create-wrapper {
      clear: none;
      display: flex;
      flex-direction: column;
      position: inherit;

      .create-link {
        display: block;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }

  main.container {
    margin-top: 150px;
    width: 100%;
    &.messages-on {
      margin-top: 0px;
    }

    .tabs.primary {
      white-space: normal;
    }
  }

  header {
    background-color: $light-grey;
    position: fixed;
    top: 0;
    width: 100%;

    .region-header {
      float: left;
      width: 80%;
    }

    .navbar-default {
      background-color: $light-grey;

      .logo {
        float: left;
        width: 20%;
      }

      .block-ab-search {
        float: right;
        top: 50px;
        width: 87%;

        input[type='text'] {
          outline: none;
          width: 100%;
        }

        .form-submit {
          right: -17px;
        }
      }
    }

    .main-menu-toggle {
      -webkit-user-select: none;
      display: block;
      position: absolute;
      right: 0;
      top: 24px;
      user-select: none;
      z-index: 1;

      .block-main-menu {
        float: none;
        position: fixed;

        ul {
          li {
            font-size: 16px;
          }
        }
      }

      input {
        -webkit-touch-callout: none;
        cursor: pointer;
        display: block;
        height: 32px;
        left: -5px;
        opacity: 0;
        position: absolute;
        top: -7px;
        width: 40px;
        z-index: 2;
      }

      span {
        background: lighten($dark-grey, 20%);
        border-radius: 3px;
        display: block;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        transform-origin: 4px 0;
        transition: transform .5s cubic-bezier(.77, .2, .05, 1), background .5s cubic-bezier(.77, .2, .05, 1), opacity .55s ease;
        width: 33px;
        z-index: 1;

        &:first-child {
          transform-origin: 0 0;
        }

        &:last-child {
          transform-origin: 0 100%;
        }
      }

      input:checked ~ span {
        background: lighten($dark-grey, 20%);
        opacity: 1;
        transform: rotate(45deg) translate(0, -1px);
      }

      input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(.2, .2);
      }

      input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }

      input:checked ~ div div ul {
        transform: translate(-100%, 0);
      }

      ul {
        -webkit-font-smoothing: antialiased;
        background: $white;
        border-left: 1px solid $color-fourth;
        bottom: 0;
        list-style-type: none;
        overflow-y: auto;
        padding: 0;
        position: fixed;
        right: -382px;
        top: 132px;
        transition: all .3s;
        width: 325px;
        z-index: 1000;

        li {
          background-color: $white;
          border-bottom: 1px solid $color-fourth;
          clear: both;
          margin-left: 0;
          padding: 10px 20px;
          width: 100%;

          a {
            color: $black;

            &:hover {
              text-decoration: none;
            }
          }

          &:last-child {
            border: 0;
          }
        }
      }
    }
  }

  .view-available-as-used,
  .view-bundles-with-product,
  .pane-parent-product-list-panel-pane-1 {
    .views-field-add-to-cart-form,
    .commerce-add-to-cart {
      display: none;
    }
  }

  .pane-bundles-with-product-panel-pane-1 {
    .view-content {
      .bundle-row {
        div {
          &:nth-child(3) {
            margin-top: 20px;
            height: auto;
          }
        }
      }
    }
  }

  .product-full {
    .sub {
      flex: 1 1 100%;

      .view-bundles-with-product {
        .bundle-row {
          flex-direction: column;
          flex-wrap: wrap;
          height: 155px;

          .product-image {
            flex: 0 0 100%;
          }

          .views-field-title {
            flex: 0 0 33%;
            width: 75%;
          }

          div:nth-child(3) {
            flex: 0 0 66%;
            height: 66%;
            margin-top: 0;
            padding-left: 10px;
            width: 75%;

            .price-container {
              margin-bottom: 0;
            }

            .price {
              label {
                margin-bottom: 0;
              }

              &.strike {
                text-align: left;
              }
            }
          }
        }
      }
    }

    .view-available-as-used {
      .views-row {
        .parent-product-middle {
          margin-left: 10px;
        }
      }
    }

    .view-parent-product-list {
      .views-row {
        .parent-product-middle {
          margin-left: 10px;
        }
      }
    }

    &.product-bundle {
      .right-col {
        .panel-pane {
            float: none;
        }
      }
      .field-bundle-product {
        .field-product-item {
          height: 212px;
        }

        .grid--container {
          height: 202px;
        }
        .left-col {
          flex-grow: 0;
        }

        .wrapper {
          .middle-col {
            flex: 2 0 101px;
            height: auto;
          }

          .right-col {
            align-self: flex-start !important;
            height: 101px;

            .pane-node-field-product-reference,
            .pane-ab-price {
              float: none;

              label {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .pane-node-product-field-product-image {
        img {
          display: none;
        }
      }

      .wrapper {
        margin-top: 100px;

        .field-collection-container {
          .field-bundle-product:last-child {
            .grid--container {
              margin-bottom: 0
            }
          }

          .field-product-item {
            .wrapper {
              margin-top: 0;
            }

            .middle-col {
              span, .field-isbn {
                display: none;
              }
            }
          }
        }
      }
    }

    .grid--container {
      align-items: flex-start;

      .wrapper {
        align-items: stretch;
        flex-direction: column;
      }

      .right-col {
        flex: auto;
        margin-top: 0;

        .price-container {
          margin-bottom: 4px;
        }

        .stock-info-list {
          ul li {
            margin-left: 0;
          }
        }

        .pane-node-field-schilling-stock {
          padding-left: 10px;
          border: 1px solid $light-blue;
        }
        .pane-product-syllabus {
          border: 1px solid $light-blue;
        }

        div {
          border-bottom: none;
          float: none;
          text-align: left;

          .strike {
            text-align: left !important;
          }
        }
      }

      div {
        text-align: left;
      }

      .form-submit {
        float: none;
      }

      .field-commerce-price {
        float: left;
        margin-top: 15px;
      }
    }
  }

  .page-search {
    main {
      .col-md-2 {
        display: none;
      }

      .col-md-10 {
        width: 100%;
      }
    }
  }

  .featured {
    display: none;
  }

  .product-full {
    .pane-node-title {
      h1 {
        margin-top: 0;
      }
    }

    .pane-details {
      label {
        display: block;
        margin-bottom: 0;
      }

      li {
        padding-bottom: 7px;
      }
    }
  }

  .node-type-bookshop-list {
    .group-middle {
      .field-google-maps-link {
        position: relative;
      }
    }
  }

  .page-checkout {
    .main-content {
      .checkout-continue {
        background-color: darken($main-blue, 20%);
      }

      input[type='submit'],
      input[type='button'] {
        margin-bottom: 10px;
        padding: 8px 25px !important;
        width: 100%;
      }

      #edit-account-form-select-login-request-password {
        padding-top: 2px;
      }

      .create-account-container {
        padding-top: 8px;
        margin-top: 22px;
      }
    }

    #commerce-checkout-form-review {
      .checkout_review {
        padding-bottom: 1.5em;
      }

      fieldset {
        padding-bottom: 20px;

        legend {
          margin-bottom: 6px;
        }

        .form-item {
          margin-top: 6px;
        }
      }

    }
  }

  #commerce-checkout-form-checkout,
  .view-id-ab_shopping_cart_summary {
    #commerce-checkout-coupon-ajax-wrapper {
      margin-top: 0;
      width: 100%;

      .form-text {
        height: 36px;
        margin-bottom: 19px;
        outline: none;
        padding: 0 10px;
        width: 100%;
      }
    }

    .form-actions,
    .checkout-buttons {
      margin-top: 0;
      width: 100%;
    }

    .line-item-summary {
      width: 100%;

      td {
        width: 50% !important;

        &:nth-child(2) {
          padding-right: 15px !important;
        }
      }

      tr {
        td {
          &:first-child {
            text-align: left;
          }
        }
      }
    }

    .views-table {
      thead {
        display: none;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        td {
          &:nth-child(1) {
            flex: 0 0 20%;

            img {
              width: 90%;
              height: auto;
            }
          }

          &:nth-child(2) {
            flex: 0 0 40%;

            .cart-amount-edit {
              bottom: 13px;
              visibility: hidden;

              div {
                visibility: visible;
              }
            }

            .delete-line-item {
              bottom: 8px;
              left: 70px;
              position: absolute;
              width: 16px;
              padding: 0 !important;
            }
          }

          &:nth-child(3) {
            display: none;
          }

          &:nth-child(4) {
            display: flex;
            flex: 1 0 100%;
            flex-wrap: wrap;
            position: absolute;
            right: 0;
            span {
              display: none;
            }
          }
          &:nth-child(5) {
            display: flex;
            flex: 1 0 100%;
            flex-wrap: wrap;
            position: absolute;
            right: 0;
            top: 65px;
          }
        }
      }
    }
  }

  .page-checkout-shipping {
    fieldset {
      width: 100%;
    }
  }

  .page-checkout-review {
    .delivery-information {
      width: 100%;

      .form-type-textfield {
        .form-text {
          width: 100%;
        }
      }
    }
  }

  .page-user {
    fieldset.group-right {
      border-left: 0;
      padding-left: 15px;
    }
  }

  .page-checkout-account {
    .or-field {
      height: 60px;
      justify-content: center;
      width: 100%;
    }

    #edit-account-form-select {
      flex-wrap: wrap;

      fieldset {
        flex: 0 0 100% !important;
        height: auto;

        &.without-login-block {
          padding-top: 40px;
        }
      }

      legend {
        width: auto;
      }
    }
  }

  .block-commerce-cart {
    left: 115px;
    position: absolute;
    z-index: 0;

    .cart-empty-block,
    .cart-summary {
      font-size: 1.3em;
      padding-left: 40px;

      &::after {
        background-position-x: 0;
        border-left: 0;
        left: 0;
        right: 24px;
        top: -2px;
        z-index: 1;
      }

      .cart-items {
        display: none;
        opacity: 0 !important;
      }
    }
  }
}
