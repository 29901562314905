.node-type-webform {
  &.page-with-custom-menu {
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: 22px;
    font-weight: 600;
    padding: 7px 0 5px;
    text-transform: uppercase;
  }
  fieldset {
    margin-bottom: 40px;
  }
  legend {
    background-color: $light-blue;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: $white;
    font-size: 16px;
    margin-bottom: 0;
    padding: 7px 15px;
    width: 100%;
  }

  .field-message {
    margin-bottom: 20px;
  }

  .field-how-to {
    margin-left: 20px;
  }

  .fieldset-wrapper {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid $light-grey;
    border-left: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    padding: 20px;

    input[type='radio'] {
      margin-right: 10px;
      vertical-align: initial;
    }

    input[type=text],
    input[type=email] {
      border: 1px solid lighten($dark-grey, 20%);
      border-radius: 4px;
      padding: 6px 10px;
    }

    select {
      background: 0 0;
      border: 1px solid lighten($dark-grey, 20%);
      height: 34px;
      margin-right: 20px;
      padding: 0 10px;
    }

    .webform-component-textfield,
    .webform-component-email {
      label {
        display: block;
      }
    }
  }
}
