@charset "UTF-8";

$slick-font-path: './fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: '../images/' !default;
$slick-arrow-color: $black !default;
$slick-dot-color: $color-fifth !default;
$slick-dot-color-active: $main-blue !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 12px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .15 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

.slick-slider {
  -ms-touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-loading & {
    background: $white slick-image-url('ajax-loader.gif') center center no-repeat;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  [dir='rtl'] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    border: 1px solid transparent;
    display: block;
    height: auto;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

@if $slick-font-family == 'slick' {
  @font-face {
    font-family: 'slick';
    src: slick-font-url('slick.eot');
    src: slick-font-url('slick.eot?#iefix') format('embedded-opentype'), slick-font-url('slick.woff') format('woff'), slick-font-url('slick.ttf') format('truetype'), slick-font-url('slick.svg#slick') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

// Pagers
.slick-prev,
.slick-next {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;

  &:hover,
  &:focus {
    background: transparent;
    color: transparent;
    outline: none;

    &::before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled::before {
    opacity: $slick-opacity-not-active;
  }

  &::before {
    color: $slick-arrow-color;
    font-family: $slick-font-family;
    font-size: 20px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1;
    opacity: $slick-opacity-default;
  }
}

.slick-prev {
  left: 25px;
  z-index: 10;

  [dir='rtl'] & {
    left: auto;
    right: 25px;
  }

  &::before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: 25px;

  [dir="rtl"] & {
    left: 25px;
    right: auto;
  }

  &::before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

// Dots
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -25px;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 54px;
  text-align: right;
  width: 100%;

  li {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 20px;

    button {
      background: transparent;
      border: 0;
      color: transparent;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 20px;
      line-height: 0;
      outline: none;
      padding: 5px;
      width: 20px;

      &:hover,
      &:focus {
        outline: none;

        &::before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &::before {
        color: $slick-dot-color;
        content: $slick-dot-character;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        height: 20px;
        left: 0;
        line-height: 20px;
        opacity: $slick-opacity-not-active;
        position: absolute;
        text-align: center;
        top: 0;
        width: 20px;
      }
    }

    &.slick-active button::before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
