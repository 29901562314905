.page-with-custom-menu {
  .field-staff-block {
    blockquote {
      background-color: $light-grey;
      border-color: $light-grey;

      &::before {
        border-color: $light-grey transparent;
      }

      &::after {
        border-color: $light-grey transparent;
      }

      span {
        font-size: 14px;
        line-height: 20px;

        &::before {
          content: '\201C';
          font-size: 22px;
          font-weight: 600;
          padding-right: 6px;
        }

        &::after {
          content: '\201E';
          font-size: 22px;
          font-weight: 600;
          padding-left: 6px;
        }
      }
    }
  }

  &.node-type-staff-list {
    &.staff-list-1 {
      .field-staff {
        width: 100% !important;

        .group-left {
          width: 15% !important;
        }

        .group-right {
          width: 80% !important;
        }
      }
    }

    .panel-col-last {

      h1 {
        margin-bottom: 5px;
      }

      .field-staff-block {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 36px;

        .field-headline {
          border-bottom: 1px solid lighten($dark-grey, 10%);
          flex: 0 0 100%;
          font-size: 1.2em;
          margin-bottom: 24px;
          text-transform: uppercase;
          width: 100%;
        }

        .field-short-information {
          margin-bottom: 10px;
        }

        .field-staff {
          margin-bottom: 28px;
          padding-right: 10px;
          width: 33.333334%;

          .group-left {
            width: 40%;
          }

          .group-right {
            width: 60%;

            .field-title {
              font-size: 1.1em;
              font-weight: 600;
              line-height: 1.2em;
              margin-bottom: 3px;
            }

            .field-label {
              float: left;
              padding-right: 6px;
            }

            .field-job-position {
              font-style: italic;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
