.pane-syllabus {
  h1 {
    color: $black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  h4 {
    clear: both;
    color: $light-blue;
    padding-left: 10px;
  }

  .subheader {
    color: lighten($black, 10%);
    font-size: 20px;
    font-weight: 400;
    margin-top: 6px;
  }

  p {
    margin-top: 16px;
  }

  .form {
    margin-top: 30px;

    .uni-group {
      margin-left: 16px;margin-right: 10px;

      &.active {
        font-weight: bold;
        color: #000;
      }
    }

    select {
      background: none;
      border: 1px solid lighten($dark-grey, 20%);
      height: 34px;
      width: 100%;
    }
  }

  .ajax-progress-throbber {
    left: 150px;
    position: relative;
    top: 40px;
  }

  .syllabus-list {
    border-top: 1px solid lighten($black, 50%);
    margin-top: 36px;
    padding-top: 36px;

    .syllabus-anchor {
      margin-top: 3px;
      float: right;
      font-size: 0.8em;
      a {
        color: $white;
      }
    }

    .recommended-button {
      @include button;

      height: 2.5em;
      margin-bottom: 20px;
      padding: 10px;
      width: 300px;

      a {
        color: $white;
      }
    }

    .fieldset {
      border: 1px solid $light-blue;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-bottom: 2.5em;
    }

    legend {
      background-color: $light-blue;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: $white;
      padding: 6px 10px;
    }

    .recommended {
      &.fieldset {
        border-color: $menu-secondary;
      }
      legend {
        background-color: $menu-secondary;
      }
    }

    .course-comment {
      border-bottom: 1px solid $light-blue;
      padding: 0 45px 20px;
      margin-bottom: 22px;

      h5 {
        color: $light-blue;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .node-product-display {
      display: flex;
      flex-flow: row wrap;
      left: 30px;
      position: relative;
      top: -27px;
    }

    .product-teaser {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;

      .form-item {
        margin-bottom: 0;
      }
    }

    .group-left {
      flex: 0 0 120px;
    }

    .group-right {
      display: flex;
      flex-direction: column;
      flex: 0 0 250px;

      .field-label {
        display: none;
      }
      .field-isbn {
        padding-bottom: 20px;
        padding-top: 8px;
        &:before {
          content: 'ISBN: ';
          margin-right: 8px;
        }
      }
    }

    .field-ab-prices {
      .price-container {
        float: inherit;
        text-align: left;
      }
    }

    h2 {
      font-size: 1.4em;
      margin: 0;

      a {
        color: $black;
      }
    }

    .field-author {
      height: 35px;
      margin-top: 6px;
      overflow-y: hidden;
    }
  }

  .syllabus-list {
    .form-submit {
      &.print {
        margin-left: 20px;
      }
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      left: -9999px;
      position: absolute;
    }

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      cursor: pointer;
      padding-left: 1.95em;
    }

    [type="checkbox"]:not(:checked) + label::before,
    [type="checkbox"]:checked + label::before {
      background: $white;
      border: 1px solid $light-grey;
      border-radius: 4px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
      content: '';
      font-weight: 100;
      height: 1.25em;
      left: 10px;
      position: absolute;
      top: 8px;
      width: 1.25em;
    }

    [type="checkbox"]:not(:checked) + label::after,
    [type="checkbox"]:checked + label::after {
      color: $main-blue;
      content: '✔';
      font-size: 2.5em;
      left: .325em;
      line-height: .7;
      position: absolute;
      top: 0;
      transition: all .2s;
    }

    [type="checkbox"]:not(:checked) + label::after {
      opacity: 0;
      transform: scale(0);
    }

    [type="checkbox"]:checked + label::after {
      opacity: 1;
      transform: scale(1);
    }

    [type="checkbox"]:disabled:not(:checked) + label::before,
    [type="checkbox"]:disabled:checked + label::before {
      background-color: #ddd;
      border-color: #bbb;
      box-shadow: none;
    }

    [type="checkbox"]:disabled:checked + label::after {
      color: $dark-grey;
    }

    [type="checkbox"]:checked:focus + label::before,
    [type="checkbox"]:not(:checked):focus + label::before {
      border: 1px dotted $main-blue;
    }

    label:hover::before {
      border: 1px solid $main-blue !important;
    }
  }
}
