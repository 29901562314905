.node-type-bookshop-list {
  h1 {
    color: $black;
    font-size: 22px;
    font-weight: 600;
    margin: 27px 0 0;
    text-transform: uppercase;
  }

  h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .field-body {
    margin-bottom: 48px;
    margin-top: 4px;
  }

  .field-store-address {
    font-size: 1.1em;
  }

  .node-store {
    display: flex;
    flex-flow: row wrap;
  }
}

.field-bookstore {
  border-top: 1px solid $dark-grey;
  margin-top: 32px;
  padding-top: 32px;

  &:last-child {
    border-bottom: 1px solid $dark-grey;
    padding-bottom: 32px;
  }

  .group-left {
    flex: 0 0 250px;
  }

  .group-middle {
    flex-grow: 1;

    .field-label {
      float: left;
      font-weight: 600;
      padding-right: 10px;
    }

    .field-store-address {
      margin-bottom: 24px;
    }

    .field-google-maps-link {
      bottom: 0;

      a {
        font-weight: 600;
      }
    }
  }

  .group-right {
    flex-grow: 1;

    h3 {
      font-size: 1em;
      font-weight: 600;
      margin: 0;
    }

    .group-office-hours {
      border: 3px solid $main-blue;
      border-radius: 10px;
      padding: 16px;

      .oh-display-label {
        width: 75% !important;
      }
    }

    .field-office-hours-comment {
      background-color: $main-blue;
      border-radius: 10px;
      color: $white;
      padding: 10px 16px;
    }
  }
}
