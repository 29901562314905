.page-node {
  &.no-left-menu {
    .pane-content {
      width: 85%;
    }
  }
}

.page-with-custom-menu {
  .panel-col-first {
    width: 20%;
  }

  .panel-col-last {
    padding-left: 20px;
    width: 80%;

    h1 {
      color: $black;
      font-size: 22px;
      font-weight: 600;
      padding: 7px 0 5px 26px;
      text-transform: uppercase;
    }

    h3 {
      color: $black !important;
      font-style: normal !important;
      font-weight: normal;
    }

    .media-element-container,
    .field-image {
      margin-top: 20px;

      img {
        height: auto;
        width: 100%;
      }
    }

    .field-body {
      margin-bottom: 24px;
    }
  }
}

.custom-menu-panel {
  .pane-title {
    background-color: $main-blue;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 12px 14px;
    text-transform: uppercase;
  }

  .pane-content {
    background-color: $light-grey;
  }

  ul {
    padding: 0;
    position: relative;

    .nolink {
      cursor: pointer;
      width: 100%;

      &.active-trail {
        &::after {
          content: '\25BC';
          position: absolute;
          right: 10px;
        }
      }
      &::after {
        content: '\25B6';
        position: absolute;
        right: 10px;
      }
    }

    .active-trail {
      ul {
        display: block;
      }
    }

    li {
      border-bottom: 1px solid $menu-secondary;
      list-style: none;
      margin: 0;
      padding: 0;

      a,
      .nolink {
        display: inline-block;
        padding: 6px 30px 6px 10px;
      }

      a {
        color: $black;
        width: 100%;

        &.active {
          background-color: $light-blue;
        }
      }

      &:last-child,
      li {
        border-bottom: 0;
      }

      ul {
        background-color: $white;
        display: none;
        padding: 0;
      }
    }
  }
}

