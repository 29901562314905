.page-user {
  &.logged-in,
  &.page-user-register {
    h1.title {
      display: none;
    }
  }
}

.page-user-register {
  .captcha {
    margin-left: 16px;
  }

  .region-content {
    width: 100% !important;
  }

  .form-item-invoice-choice {
    label {
      display: inline-block;
      position: relative;
      top: 4px;
    }
    .description {
      margin-left: 0;
      font-style: italic;
      color: $color-fifth;
    }
  }
}

.page-user-password {
  .user-forgot {
    .form-submit {
      margin-top: 16px;
    }
  }
}

.page-user-account {
  table {
    width: 100%;

    tr {
      line-height: 2em;
      background-color: transparent;
    }

    .even {
      background-color: rgba(0, 0, 0, .1);
    }
  }
}

.page-user-edit {
  label {
    color: $dark-grey;
    display: block;
    font-weight: 400;
    padding-left: 10px;
  }

  .form-submit {
    float: right;
    margin-right: 50px;
  }

  .form-item-language label {
    display: none;

    &.option {
      display: inline;
    }
  }

  input[type=text] {
    &.readonly {
      color: $dark-grey;
      cursor: not-allowed;
    }
  }
  input[type=text],
  input[type=password],
  select {
    border: 1px solid lighten($dark-grey, 20%);
    border-radius: 3px;
    padding: 6px 10px;
    width: 90%;

    &.error {
      border: 1px solid $red;
    }
  }

  input[type=password] {
    margin-bottom: 0;
  }

  input[type=radio] {
    margin-top: 0;
    vertical-align: text-top;
  }

  select {
    background: none;
    border: 1px solid lighten($dark-grey, 20%);
    height: 34px;
  }

  .group-information {
    legend {
      display: none;
    }
  }

  #edit-locale {
    margin-top: 28px;
    width: 90%;

    legend {
      border: 0;
      font-size: 18px;
      margin-bottom: 0;
    }

    .description {
      color: lighten($dark-grey, 20%);
      font-size: 16px;
      font-style: italic;
    }
  }
}

.page-user-order {
  .orders-intro {
    border: 1px solid lighten($dark-grey, 20%);
    margin: 20px 0 25px 0;
    padding: 20px 25px;
  }
}

.page-user {
  h1 {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }

  h4 {
    margin-top: 20px;
  }

  fieldset {
    &.group-left {
      h4, h4 div {
        margin-top: 0;
        margin-bottom: 17px;
      }
    }

    &.group-right {
      border-left: 1px solid lighten($dark-grey, 30%);
      padding-left: 52px;

      .form-type-item,
      .form-type-item h4 {
        margin-top: 0;
      }
    }
  }

  .form-item-profile-type-wrapper {
    margin: 10px 0;

    label {
      color: $black;
      font-size: 18px;
      font-weight: 500;
      padding-left: 0;
    }

    .form-radios {
      display: flex;

      .form-item {
        width: 33.333%;
      }

      label {
        display: inline;
        font-size: 14px;
        font-weight: 400;
        padding-left: 10px;
      }
    }
  }

  .field-name-field-phone,
  .field-name-field-firstname {
    .form-type-textfield {
      margin-top: 3px;
    }
  }

  .intro-container {
    margin-bottom: 30px;
    margin-top: 8px;

    h2 {
      color: $black;
      font-weight: 600;
      margin: 0;
    }

    .form-item {
      color: $dark-grey;
      font-size: 20px;
      margin: 0;
    }
  }

  .main-content {
    .tabs.primary {
      border: 0;
      float: left;
      padding-right: 30px;
      white-space: normal;
      width: 25%;

      li {
        border-bottom: 1px solid $light-blue;
        display: block;

        &:first-child {
          a {
            background-color: $main-blue;
            color: $white;
            text-transform: uppercase;
          }
        }

        &:last-child {
          border: 0;
        }

        a {
          background-color: $light-grey;
          border: 0;
          color: $black;
          display: inline-block;
          padding: 10px;
          width: 100%;

          &.active {
            background-color: $light-blue;
            color: $white;
          }
        }
      }
    }

    .region-content {
      float: left;
      width: 70%;

      h1 {
        margin-bottom: 0;
        margin-top: 11px;
      }
    }
  }

  .field-name-field-company-code {
    .form-text {
      margin-bottom: 6px;
    }
  }
  .description {
    color: $dark-grey;
    font-size: 14px;
  }

  .user-bookcodes {
    thead {
      th {
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
    tbody {
      td {
        height: 32px !important;

        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
  .user-orders,
  .user-bookcodes,
  .user-downloads {
    background: transparent;
    width: 100%;

    h3 {
      font-size: 16px;
      margin: 0;
    }

    th {
      border-bottom-width: 1px;
      padding-bottom: 10px;
      text-align: right;

      &:nth-child(1) {
        text-align: left;
      }
    }

    tbody {
      td {
        border-bottom: 1px solid lighten($dark-grey, 20%);
        height: 177px;
        padding-bottom: 19px;
        padding-top: 16px;
        text-align: right;

        &:nth-child(1) {
          text-align: left;
        }
      }
    }

    tr {
      background: transparent;
      border: 0;
    }

    td {
      color: $dark-grey;
      font-size: 16px;
      padding: 5px 0;
      vertical-align: top;
    }
  }

  .user-orders {
    tbody {
      td {
        height: auto;
        padding-top: 6px;

        &:nth-child(3), &:nth-child(5) {
          font-weight: 500;
        }
      }
    }
  }
}

.create-account-container {
  border-top: 1px solid lighten($dark-grey, 30%);
  margin-top: 22px;
  padding-top: 8px;

  .create-account {
    background-color: $light-blue;
    color: $white;
    display: block;
    height: 37px;
    padding-top: 8px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

.user-login,
.user-forgot,
.user-create {
  select {
    background-color: transparent;
    height: 28px;
    width: 100%;
  }

  input[type=text],
  select,
  input[type=password] {
    border: 1px solid $dark-grey;
    border-radius: 5px;
    padding: 3px 0 3px 12px;
  }

  label {
    clear: both;
    color: $dark-grey;
    display: block;
    font-weight: normal;
    padding-left: 6px;
  }

  .form-actions {
    margin: 16px 0 14px;
    text-align: right;

    .business-button {
      float: right;
      margin-right: 16px;
      margin-top: 30px;
    }
  }

  .form-type-password {
    display: inline-block;
    margin-top: 20px;
  }

  .form-type-item {
    margin-top: 4px;
  }

  .forgot-link {
    color: $light-blue;
    font-size: 12px;
  }
}

.user-create {
  .form-wrapper {
    .form-item-field-private-type-und {
      margin-top: 3px;
    }
  }
  .create-wrapper {
    clear: both;
    margin-top: 100px;
    position: absolute;
    text-align: center;
    width: 100%;

    .create-link {
      background-color: $main-blue;
      display: inline-block;
      margin-right: 100px;
      padding: 12px 30px;

      a {
        color: $white;
        font-size: 30px;
        text-transform: uppercase;
      }
    }
  }

  .group-company-details {
    .form-item {
      margin-top: 0;
    }
  }

  .group-appointment-information {
    clear: both;
  }

  .field-name-field-private-type {
    .form-type-radio {
      float: left;

      label {
        display: inline-block;
        margin-right: 20px;
        position: relative;
        top: 4px;
      }
    }
  }

  .form-type-password {
    margin: 0;
    width: 100%;
  }

  input[type=text],
  input[type=password] {
    @include make-md-column(12);
    margin-bottom: 16px;
    width: 100%;
  }

  .form-item-name {
    clear: both;
  }

  .form-item-mail {
    padding-top: 20px;
  }
}
