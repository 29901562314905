.page-cart {
  h1 {
    color: $black;
    font-size: 20px;
    font-weight: 600;
    margin: 40px 0 20px;
    text-transform: uppercase;
  }

  .footer-links {
    clear: both;
    padding-top: 30px;

    ul {
      display: block;
      width: 100%;

      li {
        padding: 0;
      }

      .line-item-summary-checkout {
        float: right;
      }
    }

    .line-item-summary-view-cart,
    .line-item-summary-checkout,
    .line-item-summary-contnue-shopping {

      a {
        background-color: $main-blue;
        border: 0;
        border-radius: 3px;
        color: $white;
        font-size: 1.1em;
        padding: 8px 25px;
        text-transform: uppercase;
      }
    }
  }
}
.block-commerce-cart {
  padding: 0;
  top: 34px;
  z-index: 100;

  .cart-summary {
    cursor: pointer;
    padding-right: 55px;
  }

  .cart-empty-block {
    padding-right: 55px;
  }

  .cart-summary,
  .cart-empty-block {

    color: $main-blue;

    position: relative;
    text-align: right;
    top: -8px;

    &::after {
      background: url('/profiles/academicbooks/themes/academic/images/icons/basket.svg') 12px 0 no-repeat;
      background-size: 27px 27px;
      border-left: 2px solid darken($light-grey, 10%);
      content: ' ';
      display: block;
      height: 26px;
      position: absolute;
      right: 0;
      top: -4px;
      width: 43px;
    }
  }

  .cart-items {
    background-color: $white;
    border: 1px solid $dark-grey;
    border-radius: 4px;
    display: none;
    padding: 10px;
    position: absolute;
    right: -30px;
    top: 30px;
    width: 350px;

    tr {
      background-color: $white;
    }
  }
}

.view-ab-shopping-cart-block {
  .line-item-summary-checkout {

    a {
      @include button;
      display: inline-block;
    }
  }

  .view-content {
    ul {
      width: 100%;
    }

    li {
      border-bottom: 1px solid lighten($dark-grey, 30%);
      display: block;
      float: left;
      margin: 0;
      padding: 10px 0;
      width: 100%;

      a:hover {
        text-decoration: none;
      }

      >div {
        float: left;

        &:nth-of-type(1) {
          color: $black;
          width: 10%;
        }

        &:nth-of-type(2) {
          padding-left: 15px;
          text-align: left;
          width: 70%;
        }

        &:nth-of-type(3) {
          color: $black;
          width: 20%;
        }
      }
    }
  }

  .view-footer {
    clear: both;
    color: $black;

    .line-item-total-label {
      font-weight: 400;
    }

    .line-item-total-raw {
      font-weight: 600;
    }

    .line-item-summary {
      width: 100%;

      tbody {
        border: 0;
      }

      td {
        display: inline-block;
      }

      .line-item-subtotal-label {
        padding-right: 12px;
      }

      .line-item-subtotal {
        font-weight: 600;
      }
    }
  }
}

.view-ab-shopping-cart-summary,
.cart_contents_form,
.commerce-line-item-views-form {
  tr {
    border-bottom: 1px solid $light-grey;
  }
}

.view-ab-shopping-cart-form,
.cart_contents_form,
.cart_contents {
  table {
    border: 0;
    width: 100%;

    thead {
      th {
        border-bottom: 1px solid lighten($dark-grey, 30%);
        padding-bottom: 6px;
        text-transform: uppercase;
      }

      th:nth-child(4),
      th:nth-child(5) {
        text-align: right;
      }
    }

    tbody {
      border: 0;
    }

    tr {
      background-color: transparent;
    }

    td {
      padding-top: 28px;
      vertical-align: top;

      td {
        padding-top: 0;
        width: auto !important;
      }

      &:nth-child(1) {
        padding-right: 20px;
        width: 11%;
      }

      &:nth-child(2) {
        width: 37%;

        h4 {
          margin-top: 0;
        }
      }

      &:nth-child(3) {
        color: lighten($dark-grey, 10%);
        font-style: italic;
        padding-right: 50px;
        width: 15%;
      }

      &:nth-child(4) {
        width: 17%;
      }

      &:nth-child(5) {
        width: 17%;
      }

      .field-line-item-comment {
        color: $dark-grey;
        margin-top: 1em;
        font-style: normal;
      }
    }

    .field-product-image {
      margin-bottom: 28px;
    }
  }

  .field-stock-sku {
    &.cart-stock {
      .stock-info-list {
        display: none;
      }
    }
  }

  .delete-line-item {
    background: url('/profiles/academicbooks/themes/academic/images/icons/trash.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 20px;
    margin: 17px 0 0 5px;
    padding: 0;
    text-indent: -999999em;
    width: 20px;
  }

  td:nth-child(4),
  td:nth-child(5) {
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    vertical-align: top;
    .old-price {
      color: $dark-grey;
      text-decoration: line-through;
    }
    .price-discount {
      width: 100%;
    }
  }

  td:nth-child(4) {
    font-weight: normal;
  }

  a {
    &.field-title {
      color: $black;
      font-weight: 600;
    }
  }

  .field-author {
    font-style: italic;
    margin-top: 6px;
    text-transform: lowercase;

    a {
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .cart-amount-edit {
    float: left;

    .form-type-textfield {
      display: inline-block;

      input {
        text-align: center;
      }
    }
  }

  .line-item-summary {
    float: right;
    width: 300px;

    .line-item-total,
    .line-item-total-label {
      font-weight: 600;
      padding-top: 20px;
    }

    .line-item-discount-container {
      color: $main-blue;
    }

    .line-item-total-container {
      border-bottom: 1px solid $main-blue;
      color: $main-blue;
      font-size: 18px;
      margin-top: 30px;
    }

    .line-item-discount,
    .line-item-discount-label {
      padding-top: 0;
    }

    .line-item-subtotal-container,
    .line-item-discount-container,
    .line-item-delivery-container,
    .line-item-total-container {
      text-align: right;
      width: 300px;
    }

    .line-item-subtotal-label,
    .line-item-discount-label,
    .line-item-delivery-label,
    .line-item-total-label {
      text-align: right;
      width: 150px;
    }

    .line-item-subtotal,
    .line-item-discount,
    .line-item-delivery,
    .line-item-total {
      padding-right: 0;
      text-align: right;
      width: 150px;
    }

    .line-item-delivery-label,
    .line-item-delivery {
      padding: 0;
    }

    ul.links.inline {
      display: inline-block;
      margin-top: 60px;
    }
  }

  .commerce-line-item-actions {
    text-align: left;
  }

  #edit-checkout {
    float: right;
  }
}
