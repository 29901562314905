.search-api-sorts {
  li {
    float: left;
    list-style: none;
  }
}

.pane-search-api-page-result {
  margin-top: 30px;

  .price-container {
    margin-bottom: 0;
  }

  .pager {
    .pager-current {
      color: $main-blue;
      font-weight: normal;
    }
  }

  .pane-content {
    .row {
      border-bottom: 1px solid lighten($dark-grey, 30%);
      padding: 34px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .left-col {
      min-width: 125px;
    }

    .right-col {
      div {
        text-align: right;
        float: none;
      }
    }

    .form-submit {
      margin-top: 16px;
    }
  }
}


.page-search {
  .facets {
    border-bottom: 1px solid darken($light-grey, 10%);
    cursor: pointer;
    font-size: 13px;

    &:first-child {
      margin-top: 64px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .pane-title {
      background-color: $light-grey;
      padding: 7px 0 7px 20px;
    }

    .pane-content {
      display: none;

      ul {
        li {
          list-style: none;
          padding: 4px 0 0 10px;

          &:first-child {
            padding-top: 8px;
          }

          a {
            color: $black;
            display: inline-block;

            &.facetapi-active {
              padding-right: .5em;
            }
          }
        }
      }
    }
  }
}
