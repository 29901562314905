@mixin device($media) {
  @if $media == phone {
    @media screen and (max-width: 700px) {
      @content;
    }
  } @else if $media == tablet {
    @media screen and (max-width: 945px) {
      @content;
    }
  } @else if $media == landscape {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      @content;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  } @else if $media == midscreen {
    @media screen and (max-width: 1228px) {
      @content;
    }
  } @else if $media == screen {
    @media screen and (max-width: 1030px) {
      @content;
    }
  }
}

@mixin button {
  background-color: $main-blue;
  border: 0;
  border-radius: 3px;
  color: $white;
  font-size: 1.1em;
  padding: 8px 25px;
  text-transform: uppercase;
}
