.used-books {
  .view-mode-product_teaser {
    .field-ab-prices,
    .field-product-reference {
      display: none;
    }
  }
}

.view-bundles-with-product {
  .bundle-row {
    margin-bottom: 1.5em;
  }
}

.product-references {
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal;
  list-style: none;
  padding-left: 0;

  li {
    opacity: 0.4;
    filter: grayscale(100%);
    border: 1px solid #ccc;
    color: #ccc;
    margin-bottom: 16px;
    margin-right: 16px;
    width: 120px;
    &.active {
      border: 2px solid #7ea4b3;
      color: #7ea4b3;
      filter: grayscale(0);
      opacity: 1;
      a {
        color: #7ea4b3 !important;
      }
      .spacer {
        border-color: #7ea4b3 !important;
      }
    }
    a {
      color: #aaa;
      text-decoration: none;
      &:hover {
        color: darken(#ccc, 20%);
        transition: ease-in .4s;
      }
    }
    .spacer {
      border-bottom:3px dotted #ccc;
      height: 3px;
      margin: 6px 0px;
    }
    .title {
      color: #000;
      font-size: 1.1em;
      font-weight: 600;
      text-align: center;
    }
      div.icon {
        font-size:32px;
        margin: 0 16px;
        text-align: center;

      }
    div.price {
      color: #000;
      font-weight: 600;
      line-height: 1.1em;
      margin: 4px 0;
      text-align: center;

      &.muted {
        color: lighten(#000, 50%);
      }
      small {
        font-weight: 400;
      }
    }
  }
}

.variants {
  display: none;
  select {
    margin-bottom: 14px;
    padding: 4px 35px 4px 10px;
    font-size: 16px;
    border: 3px solid #ccc;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  padding-left: 0;
  margin-top: 20px;
   li {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     text-align: left;
     border: 3px solid #ccc;
     border-radius: 10px;
     list-style: none;
     padding: 4px 10px;
     margin-bottom: 8px;
     font-size: .8em;
       font-weight: 500;

     input {
       margin-right: 6px;
     }
     input[type=radio] {
       --active: #7ea4b3;
       --active-inner: #fff;
       --focus: 2px #7ea4b3;
       --border: #BBC1E1;
       --border-hover: #275EFE;
       --background: #fff;
       --disabled: #F6F8FF;
       --disabled-inner: #E1E6F9;
       -webkit-appearance: none;
       -moz-appearance: none;
       height: 18px;
       outline: none;
       display: inline-block;
       vertical-align: top;
       position: relative;
       margin: 0;
       cursor: pointer;
       border: 1px solid var(--bc, var(--border));
       background: var(--b, var(--background));
       transition: background .3s, border-color .3s, box-shadow .2s;
       &:after {
         content: '';
         display: block;
         left: 0;
         top: 0;
         position: absolute;
         transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
       }
       &:checked {
         --b: var(--active);
         --bc: var(--active);
         --d-o: .3s;
         --d-t: .6s;
         --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
       }
       &:disabled {
         --b: var(--disabled);
         cursor: not-allowed;
         opacity: .9;
         &:checked {
           --b: var(--disabled-inner);
           --bc: var(--border);
         }
         & + label {
           cursor: not-allowed;
         }
       }
       &:hover {
         &:not(:checked) {
           &:not(:disabled) {
             --bc: var(--border-hover);
           }
         }
       }
       &:focus {
         box-shadow: 0 0 0 var(--focus);
       }
       &:not(.switch) {
         width: 18px;
         &:after {
           opacity: var(--o, 0);
         }
         &:checked {
           --o: 1;
         }
       }
       & + label {
         font-size: 14px;
         line-height: 18px;
         display: inline-block;
         vertical-align: top;
         cursor: pointer;
         margin-left: 4px;
       }
       border-radius: 50%;
       &:after {
         width: 16px;
         height: 16px;
         border-radius: 50%;
         background: var(--active-inner);
         opacity: 0;
         transform: scale(var(--s, .7));
       }
       &:checked {
         --s: .5;
       }
     }
  }
}

.node-type-product-list {
  .view-mode-product_teaser {
    h2 {
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 160px;
    }

    .field-author {
      line-height: 1.3em;
    }

    .price-container {
      margin-bottom: 8px;

      .strike label {
        margin-bottom: 0;
      }
    }
  }

  &.no-left-menu {
    h1 {
      color: #414141;
      font-size: 22px;
      font-weight: 600;
      padding: 7px 0 5px 0;
      text-transform: uppercase;
    }

    .pane-content {
      width: 100%;
    }

    .field-collection-view {
      margin: 0;
    }

    .field-collection-item-field-product-block {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .field-headline {
        border-bottom: 1px solid #979797;
        flex: 1 100%;
        font-size: 18px;
        margin-bottom: 26px;
        text-transform: uppercase;
      }

      .field-product {
        display: flex;
        flex: 0 370px;
        flex-flow: row wrap;
        justify-content: flex-start;

        .view-mode-product_teaser {
          flex-grow: 1;
        }
      }
    }
  }
}

.field-collection-item-field-product-block,
.used-books,
.frontpage-block {

  .view-mode-product_teaser {
    display: flex;
  }

  .views-field-title {
    flex: 1 100%;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .views-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &::after {
      content: '';
      flex: auto;
      width: 285px;
    }
  }

  .field-author {
    font-size: 12px;
    font-style: italic;
  }

  .field-product,
  .field-productdisplay-reference {
    flex: 0 380px;
    margin-bottom: 24px;

    .group-left {
      flex: 0 1 auto;
      margin-right: 15px;
      width: 27%;
    }

    .group-right {
      flex: 0 1 auto;
      padding-right: 10px;
      position: relative;
      width: 73%;
    }

    .field-ab-prices {
      bottom: 28px;
      position: absolute;
    }

    h2 {
      font-size: 14px;
      margin-top: 0;

      a {
        color: $black;
      }
    }

    .component-type-discount {
      display: none;
    }

    .field-product-reference {
      bottom: 0;
      position: absolute;
    }
  }
}

.page-with-custom-menu {
  .panel-col-last {
    h1 {
      padding-left: 0;
    }
  }

  .pane-dynamic-menu {
    a {
      color: $black;
    }
  }

  h2 {
    font-size: 14px;
    margin-top: 0;
  }

  .field-collection-container {
    border-bottom: 0;
  }

  .field-product-block {
    margin-bottom: 36px;
  }

  .field-collection-view {
    border-bottom: 0;
    margin-left: 0;
    padding: 0;

    .group-left {
      width: 40%;
    }

    .group-right {
      width: 60%;
    }
  }

  .node-product-display {
    position: relative;
  }

  .field-product-reference {
    bottom: 0;
    position: absolute;
  }

  .node-product-list {
    .field-product {
      float: left;
      margin-bottom: 30px;
      width: 33%;
    }

    .field-headline {
      border-bottom: 1px solid $dark-grey;
      font-size: 18px;
      margin-bottom: 26px;
      text-transform: uppercase;
    }
  }
}

.product-full.product-bundle {
  .field-collection-container {
    border: none;

    .right-col {
      align-self: flex-end;

      .price-container {
        margin-bottom: 0;
      }
    }
  }

  .middle-col {
    .grid--container {
      .left-col {
        flex-basis: 125px;
      }
    }
  }
}

.page-search .grid--container,
.product-bundle .grid--container,
.product-teaser {
  border-bottom: 1px solid $color-fourth;
  margin-bottom: 24px;
  padding-bottom: 24px;

  .commerce-product-title-label {
    display: none;
  }

  .left-col {
    flex-basis: 125px;
    flex-shrink: 0;
  }

  .divider {
    width: 100%;
  }

  .right-col {
    flex-basis: 225px;
  }

  .commerce-product-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .field-author {
    font-size: 13px;
    font-style: italic;

    a {
      font-weight: 600;
    }
  }

  .field-schilling-stock {
    font-size: 13px;
    margin: 32px 0 20px;
    text-align: right;
  }

  .field-edition-year {
    margin: 10px 0;
  }

  .field-vocab-format,
  .field-isbn {
    display: flex;
  }

  .field-label,
  .inline {
    float: left;
  }

  .field-label {
    clear: both;
    padding-right: 6px;
    width: auto;
  }
}

.pane-node-product-field-product-image {
  .field-product-image {
    &.product-ebook,
    &.product-digitalaccess,
    &.product-used-book {
      width: auto;
    }
  }
}

.field-product-image {
  &.product-ebook,
  &.product-digitalaccess,
  &.product-used-book {
    display: inline-block;
    position: relative;
    width: 104px;

    &::after {
      background: url('/profiles/academicbooks/themes/academic/images/icons/ebook.svg') 100% 100% no-repeat;
      background-size: 100px;
      bottom: -3px;
      content: '';
      left: 0;
      position: absolute;
      right: 0px;
      top: 0;
    }
  }

  &.product-used-book {
    &::after {
      background: url('/profiles/academicbooks/themes/academic/images/icons/usedbook.svg') 100% 100% no-repeat;
      background-size: 100px;
    }
  }
  &.product-digitalaccess {
    &::after {
      background: url('/profiles/academicbooks/themes/academic/images/icons/digitalaccess.svg') 100% 100% no-repeat;
      background-size: 100px;
    }
  }
}

.product-full {
  .middle-col {
     padding-right: 24px;
  }
  .field-product-image {
    &.product-ebook,
    &.product-digitalaccess,
    &.product-used-book {
      &::after {
        right: -4px;
      }
    }
  }
  .pane-parent-product-list-panel-pane-1,
  .pane-available-as-used-panel-pane-1 {
    border-top: 1px solid $menu-secondary;
    margin-top: 36px;

    .pane-title {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }

  .pane-node-product-field-body {
    .field-body {
      &.collapsed {
        max-height: 155px;
        overflow-y: hidden;
        position: relative;

        &:after {
          background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1) 90%);
          bottom: 0;
          content: "";
          height: 6em;
          left: 0;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
      }
    }

    .body-expander {
      color: $main-blue;
      display: block;
      float: right;
      margin-top: 1px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .view-parent-product-list,
  .view-id-available_as_used {
    .views-row {
      display: flex;
      margin-bottom: 16px;

      .views-field-add-to-cart-form {
        align-self: flex-end;
        flex: 1;
      }

      .parent-product-middle {
        flex-grow: 1;
        margin-left: 6px;

        .author {
          margin-bottom: 10px;
        }

        .title {
          margin-bottom: 10px;

          a {
            color: $main-blue;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      .right-container {
        .cart-stock {
          text-align: right;
          margin-bottom: 10px;

          .stock-info-container {
            font-weight: normal;
          }

          .stock-info-list {
            display: none;
          }
        }

        .price.strike {
          text-align: right;
        }
      }
    }
  }
}

.product-full,
.product-full.product-bundle {
  h1 {
    color: $color-second;
    font-size: 34px;
    font-weight: 600;
  }
  em {
    font-style: normal;
  }

  main {
    margin-top: 30px;
  }

  .pane-node-product-field-bundle-product {
    h5 {
      margin-bottom: 20px;
    }
  }

  .field-bundle-product {
    .view-mode-full {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  .top-left-col {
    margin-bottom: 16px;
  }
  .left-col {
    flex-basis: 250px;
    flex-shrink: 0;
    min-width: 125px;
  }

  .right-col {
    flex-basis: 225px;
    margin-top: 23px;

    .panel-pane {
      display: flex;
      float: right;
      width: 100%;

      .pane-content {
        width: 100%;
      }
    }

    .pane-product-type-info {
      margin-top: 20px;
      text-align: right;
    }

    .field-product-reference {
      clear: both;
    }

    .price-container {
      font-size: 17px;

      .strike {
        font-size: 14px;
        text-align: right;
      }
    }

    .pane-node-field-schilling-stock {
      margin-top: 20px;
      padding: 10px 10px 8px 10px;
      text-align: right;
      border: 1px solid $light-blue;
      border-radius: 10px;
    }

    .pane-product-syllabus {
      border-radius: 10px;
      border: 1px solid $light-blue;
      margin-top: 3rem;
      padding: 10px 10px 8px 10px;
      text-align: right;

      @media (max-width: 991px) {
        .pane-content {
          margin-left: 0;
        }
      }

      h5 {
        border-bottom: 1px solid #d3d3d3;
        font-weight: 600;
        padding-bottom: 5px;
      }
      ul {
        font-size: 14px;
        list-style: none;
        margin-left: 0;
        padding-left: 0;
      }

      .pane-content {
        margin-left: 0;

        & > ul > li {
          margin: 0 0 1em 0;
        }

        div {
          border-bottom: 1px solid #d3d3d3;
        }
      }
    }
  }

  .form-submit {
    float: right;
  }

  .field-subtitle {
    font-style: italic;
    margin-bottom: 12px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .field-author {
    margin-top: 10px;

    a {
      color: $main-blue;
      font-weight: 700;
      font-size: 16px;

      font-style: italic;
    }
  }

  .pane-node-product-field-rating {
    margin: 22px 0;
  }

  .field-field-product-image {
    margin-top: 22px;
  }

  .product-details {
    font-size: 14px;
    margin-top: 20px;

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    .field-label {
      float: left;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }

    .ceb-rights {
      h5 {
        font-weight: bold;
      }

      border-top: 1px solid $dark-grey;
      margin-right: 30px;
    }

    .bold {
      font-weight: bold;
    }

    .border-bottom {
      border-bottom: 1px solid $color-fifth;
      margin-bottom: 6px;
    }

  }

  .group-above-right {
    margin-top: 105px;
  }

  .field-additional-text {
    border-top: 1px solid lighten($color-fifth, 30%);
    margin-top: 22px;
    padding-top: 22px;
  }

  .stock-info-container {
    font-weight: 600;

    .stock-info-list {
      border-bottom: 1px solid lighten($color-fifth, 30%);
      border-top: 1px solid lighten($color-fifth, 30%);
      color: $black;
      margin: 3px 0 14px;
      padding: 0;

      h5 {
        font-weight: normal;
      }

      ul {
        color: $main-blue;
        list-style-type: none;
        margin-bottom: 3px;
      }

      li {
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.price-container {
  float: right;
  font-size: 13px;
  margin-bottom: 1.5em;
  text-align: right;

  .strike {
    color: $dark-grey;
    font-weight: 400;
    text-align: left;
    text-decoration: line-through;

    label {
      font-weight: 400;
    }
  }

  .your-price {
    font-weight: 600;

    label {
      text-transform: uppercase;
    }
  }
}
