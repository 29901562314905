footer {
  bottom: -227px;
  margin-bottom: 0;
  margin-top: 50px;
  position: relative;
  position: sticky;

  .region-footer-first {
    background-color: $black;
    position: relative;

    a {
      color: $white;
    }
    ul {
      display: flex;
      justify-content: space-around;
      margin-bottom: 0;
      padding: 0;
    }

    li {
      font-size: 16px;
      font-weight: 500;
      list-style: none;
      margin: 15px 0;
      text-transform: uppercase;

      &:before {
        top: 14px;
      }

      a {
        padding-left: 34px;
      }
    }
  }

  .second {
    height: 100%;
    padding-bottom: 20px;
    width: 100%;

    .nolink {
      display: none;
    }

    .icon {
      &::before {
        margin: 0 auto;
      }
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-top: 26px;
      padding-left: 0;

      ul {
        display: block;
        margin-top: 10px;
      }

      li {
        flex-basis: 25%;
        list-style: none;

        li {
          display: inline-block;
          text-align: center;
          width: 100%;

          a {
            color: $dark-grey;
            font-size: 1.1em;
          }
        }
      }
    }
  }
}
