@import 'base/variables';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'base/mixins';
@import 'base/base';
@import 'layout/slick';
@import 'layout/header';
@import 'layout/basket';
@import 'layout/bookstores';
@import 'layout/checkout';
@import 'layout/frontpage';
@import 'layout/user';
@import 'layout/content';
@import 'layout/search';
@import 'layout/staff';
@import 'layout/page';
@import 'layout/product';
@import 'layout/variants';
@import 'layout/bundles';
@import 'layout/syllabus';
@import 'layout/footer';
@import 'layout/webform';
@import 'base/responsive';

.icon {
  height: 46px;

  .icon-academicbook {
    background: $main-blue;
    display: inline-block;
    height: 38px;
    margin-top: 6px;
    mask-size: 40px;
    mask: url("/profiles/academicbooks/themes/academic/images/academicbook.svg");
    width: 33px;
  }
}

.pane-node-product-field-ceb-variants-2 {
  & > div {
    width: 100%;
  }
  float: inherit !important;

  .select {
    .select-css {
      display: flex;
      font-size: 16px;
      //font-family: sans-serif;
      font-weight: 700;
      color: #444;
      line-height: 1.2;
      padding: .4em .2em .3em 0.2em;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      margin: 0;
      border: 1px solid #aaa;
      box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
      border-radius: .5em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background-image: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    }
    /* Focus style */
    .select-css:focus {
      border-color: #aaa;
      /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }

    /* Set options to normal weight */
    .select-css option {
      font-weight:normal;
    }
  }
}
