.jquery-modal.blocker {
  z-index: 1000;
}
.commerce-add-to-cart div[id^="edit-ceb-variants--"] {
  margin-top: 0;
}
.variants-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  justify-content: space-between;
  align-content: space-between;
  padding-left: 0;

  .variant {
    border: 2px solid #ccc;
    padding: 4px 8px;
    margin-bottom: 12px;

    label {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      input {
        margin-right: .3em;
      }

      .variant-left {
        flex-grow: 1;
      }

      .variant-right {
        flex-grow: 1;
        text-align: right;
      }

      .variant-description {
        width: 100%;
        flex-grow: 2;
        font-size: .9em;
        font-weight: 400;
        color: #999;

        .epubtype {
          color: $black;
          font-weight: 600;
        }
        .description {
          transition: opacity .2s ease-out;
          opacity: 0;
          height: 0;
          overflow: hidden;

          &.visible {
            opacity: 1;
            height: auto;
          }
        }
      }
    }
  }
}
