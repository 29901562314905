body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

main {
  flex: auto;
}

.main-content {
  min-height: 650px;
}

// Page teaser texts.
.field-teaser {
  // Set all teaser font size and color.
  color: lighten($dark-grey, 10%);
  font-size: 1.2em;
}

#sliding-popup {
  background-color: $white;
  border: 3px solid $main-blue;
  border-radius: 10px;
  bottom: 0;
  padding: 20px;
  position: absolute;
  right: 40px;
  width: 450px;

  .popup-content {
    max-width: 100%;

    #popup-text {
      max-width: 100%;
    }

    #popup-buttons {
      clear: both;
      float: left;
      margin-top: 10px;
      max-width: 100%;
      width: 100%;

      .find-more-button {
        background-color: transparent;
        border: 0;
        color: $main-blue;
        font-weight: bold;
        padding-top: 8px;
      }

      .agree-button {
        background-color: $main-blue;
        border: 0;
        border-radius: 3px;
        color: $white;
        float: right;
        font-size: 1.1em;
        padding: 8px 25px;
        text-transform: uppercase;
      }
    }
  }
}
