.frontpage-carousel {
  margin: 40px 0;

  .element {
    text-align: center;

    .field-image {
      img {
        margin: 0 auto;
      }
    }
  }
}
