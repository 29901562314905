header {
  z-index: 100;

  .logo {
    img {
      left: -50px;
      padding-top: 25px;
      position: relative;
      width: 168px;
    }
  }

  ul {
    float: right;
    list-style: none;
    margin-bottom: 0;
    padding: 10px 0;

    .leaf {
      float: left;
      list-style: none;
      padding: 0;
    }
  }

  .main-menu-toggle {
    span {
      display: none;
    }

    input {
      display: none;
    }

    .block-main-menu {
      float: right;
      margin-top: 12px;
      position: relative;

      ul {
        li {
          font-size: 12px;
          margin-right: 40px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .ab-search-container {
    position: relative;
    top: 3px;

    .search-term {
      border-radius: 4px;
      border: 1px solid lighten($dark-grey, 30%);
      font-size: 14px;
      height: 44px;
      outline: none;
      text-transform: uppercase;
    }

    .form-submit {
      background-image: url('/profiles/academicbooks/themes/academic/images/icons/search.svg');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      border: 0;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      display: block;
      height: 42px;
      position: absolute;
      right: 1px;
      text-indent: -999999px;
      top: 1px;
      width: 42px;
    }
  }

  .featured {
    background-color: $light-grey;
    font-size: 13px;

    .block-language-menu {
      float: right;

      a {
        color: $dark-grey;
      }

      li {
        float: left;
        font-weight: 300;

        &:first-child {
          &::after {
            content: '/';
            font-weight: 300;
            margin: 0 5px;
          }
        }

        &.active {
          font-weight: 500;
        }
      }
    }

    .block-menu {
      float: left;
      text-transform: uppercase;

      li {
        font-weight: 500;
        margin-right: 55px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

      }
    }
  }
}
